var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"page-header pd"},[_c('sc-button',{attrs:{"icon":"plus"},on:{"click":function($event){return _vm.$router.push("new")}}},[_vm._v("Add "+_vm._s(_vm.resource.name))]),(_vm.table)?_c('sc-search',{attrs:{"table":_vm.table,"field":"q"}}):_vm._e()],1),(_vm.table)?_c('sc-table',{attrs:{"table":_vm.table},scopedSlots:_vm._u([{key:"column-image",fn:function(ref){
var row = ref.row;
return [(row.attributes.bg_image)?_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":_vm.imageURL(row)}})]):_vm._e()]}},{key:"column-edit",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"edit","padded":false,"clickable":false}},[_c('sc-table-button',{attrs:{"icon":"edit"},on:{"click":function($event){return _vm.edit(row)}}})],1)]}},{key:"column-start_date",fn:function(ref){
var row = ref.row;
return [_c('sc-table-date-field',{attrs:{"table":_vm.table,"row":row,"field":"start_date"}})]}},{key:"column-end_date",fn:function(ref){
var row = ref.row;
return [_c('sc-table-date-field',{attrs:{"table":_vm.table,"row":row,"field":"end_date"}})]}},{key:"column-details",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"details","padded":false,"clickable":false}},[_c('sc-table-button',{attrs:{"icon":"info"},on:{"click":function($event){return _vm.details(row)}}})],1)]}},{key:"column-delete",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"delete","padded":false,"clickable":false}},[_c('sc-table-button-delete',{on:{"destroy":function($event){return _vm.destroy(row)}}},[_vm._v(" Do you wish to delete the "+_vm._s(_vm.resource.name)+" with "),(row.attributes.title)?[_vm._v("the title \""+_vm._s(row.attributes.title)+"\"")]:[_vm._v("id "+_vm._s(row.id))],_vm._v(" ? ")],2)],1)]}}],null,false,3102006786)}):_vm._e(),(_vm.showDetails)?_c('template-details',{attrs:{"item":_vm.showDetails,"type":"Message"},on:{"close":function($event){_vm.showDetails = null;}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }