<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="plus" @click="$router.push(`new`)">Add {{ resource.name }}</sc-button>
            <sc-search :table="table" field="q" v-if="table"></sc-search>
        </div>
        <sc-table :table="table" v-if="table">

            <template #column-image="{row}">
                <div class="image" v-if="row.attributes.bg_image">
                    <img :src="imageURL(row)"/>
                </div>
            </template>

            <template #column-edit="{row}">
                <sc-table-cell :table="table" field="edit" :padded="false" :clickable="false">
                    <sc-table-button @click="edit(row)" icon="edit">
                    </sc-table-button>
                </sc-table-cell>
            </template>

            <template #column-start_date="{row}">
                <sc-table-date-field :table="table" :row="row" field="start_date"/>
            </template>
            <template #column-end_date="{row}">
                <sc-table-date-field :table="table" :row="row" field="end_date"/>
            </template>

            <template #column-details="{row}">
                <sc-table-cell :table="table" field="details" :padded="false" :clickable="false">
                    <sc-table-button @click="details(row)" icon="info">
                    </sc-table-button>
                </sc-table-cell>
            </template>

            <template #column-delete="{row}">
                <sc-table-cell :table="table" field="delete" :padded="false" :clickable="false">
                    <sc-table-button-delete @destroy="destroy(row)">
                        Do you wish to delete the {{ resource.name }} with
                        <template v-if="row.attributes.title">the title "{{ row.attributes.title }}"</template>
                        <template v-else>id {{ row.id }}</template>
                        ?
                    </sc-table-button-delete>
                </sc-table-cell>
            </template>


        </sc-table>

        <template-details v-if="showDetails" :item="showDetails" @close="showDetails = null;" type="Message"/>
    </div>
</template>

<script>
import ScSearch from "@/components/sc-search";
import TemplateDetails from "@/views/app/templates/components/template-details";
import ScTableDateField from "@/components/common/sc-table-date-field";

export default {
  name: "all",
  components: {ScTableDateField, TemplateDetails, ScSearch},

  data() {
    return {
      table: null,
      showDetails: null,
    }
  },

  props: {
    passed: {
      required: true,
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.table = this.$stable.createTable({
      name: `List of ${this.resource.plural}`,
      url: `templates`,
      fetchArguments: () => {
        return '&is_template=0'
      },
      columns: {
        id: {
          width: 100,
          sort: false,
        },
        image: {sort: false,},
        name: {
          target: 'attributes.title',
          fill: true,
          sort: false,
        },
        start_date: {
          target: 'attributes.start_date',
          type: 'date',
          sort: false,
        },
        end_date: {
          target: 'attributes.end_date',
          type: 'date',
          sort: false,
        },
        edit: {width: 70, sort: false,},
        details: {width: 80, sort: false},
        delete: {width: 80, sort: false},
      },
      hover: false,
    });
    this.table.fetch();
  },

  methods: {
    search() {
      this.table.fetch();
    },

    destroy(row) {
      this.$talker.api.delete(`${this.resource.api}/${row.id}`)
        .then(res => {
          this.table.fetch();
          this.$notify.success(`Message deleted`)
        })
    },

    edit(row) {
      this.$router.push(`${row.id}/edit?tab=6`)
    },

    details(row) {
      this.showDetails = row
    },

    imageURL(row) {
      return process.env.VUE_APP_AWS_URL + row.attributes.bg_image;
    }
  }
}
</script>

<style scoped lang="scss">
.btns {
    text-align: center;
    padding: 2em 0 0.5em;
}

.image {
    height: 4em;
    overflow: hidden;
    padding: 0.5em 0;

    img {
        height: 100%;
        object-fit: contain;
    }
}
</style>